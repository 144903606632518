import { Vue, Component, Prop } from 'vue-property-decorator'


@Component
export class PopupWrapperComponent extends Vue {

    @Prop({default: 'left-to-right'}) 
    transitionType!: string;

    public showContent:boolean = false;
    public showPopup:boolean = false;

    static popupCount: number = 0; 
   
      
    public show(){
      this.showPopup = true;
      PopupWrapperComponent.popupCount++;
      /* istanbul ignore next */
      setTimeout(()=>{
        this.showContent = true;
      }, 0)
    }
    public hide(){
      this.showContent = false;
      PopupWrapperComponent.popupCount--;
    }
    public afterLeave(){
      this.showPopup = false;
    }   
    

}
